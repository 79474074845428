import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import DefaultLayout from "/opt/build/repo/docs/src/components/Guide.js"
import Button from 'components/Button.js';

export default ({components, ...props}) => <MDXTag name="wrapper" Layout={DefaultLayout} layoutProps={props} components={components}>


<MDXTag name="p" components={components}>{`Branding and design guidelines for HealthShare and its products. Anyone producing public-facing material for HealthShare should try to follow the rules documented here. Official assets, fonts, logos, images, templates etc. can be downloaded to assist with this.`}</MDXTag>
<Button
    href="https://www.wrike.com/frontend/requestforms/index.html?token=eyJhY2NvdW50SWQiOjIwOTgzNzksInRhc2tGb3JtSWQiOjEyNzQ3OX0JNDY3OTU5NDI3NTU1NQlmYzk2NDkwYTc1ZGNkYjQxNTYzNzE0ZGNkZDllZDljNDVmMDBhOTZhZmNiOTdkYjhlZTM2ODEzZDlkNWM0NzM0"
    label="Request a design project"
/>
<MDXTag name="hr" components={components}></MDXTag>
<MDXTag name="h2" components={components}>{`Maintaining these guides`}</MDXTag>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul">{`Write markdown documents filed under `}<MDXTag name="inlineCode" components={components} parentName="li">{`/pages`}</MDXTag></MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Guides are `}<MDXTag name="a" components={components} parentName="li" props={{"href":"https://mdxjs.com/"}}>{`written in MDX,`}</MDXTag>{` which means React components can be imported`}</MDXTag>
</MDXTag>
<MDXTag name="h2" components={components}>{`Front matter`}</MDXTag>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul">{`Give documents a status; `}<MDXTag name="inlineCode" components={components} parentName="li">{`stub`}</MDXTag>{`, `}<MDXTag name="inlineCode" components={components} parentName="li">{`draft`}</MDXTag>{` or `}<MDXTag name="inlineCode" components={components} parentName="li">{`done`}</MDXTag></MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Assign a category; `}<MDXTag name="inlineCode" components={components} parentName="li">{`branding`}</MDXTag>{`, `}<MDXTag name="inlineCode" components={components} parentName="li">{`assets`}</MDXTag>{` or `}<MDXTag name="inlineCode" components={components} parentName="li">{`process`}</MDXTag></MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Update `}<MDXTag name="inlineCode" components={components} parentName="li">{`date`}</MDXTag>{` whenever something changes in the format `}<MDXTag name="inlineCode" components={components} parentName="li">{`YYYY-MM-DD`}</MDXTag></MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Make sure you set a `}<MDXTag name="inlineCode" components={components} parentName="li">{`title`}</MDXTag>{` and `}<MDXTag name="inlineCode" components={components} parentName="li">{`path`}</MDXTag>{` (equal to the file name) for the guides list to work correctly`}</MDXTag>
</MDXTag></MDXTag>

export const _frontmatter = {};

  